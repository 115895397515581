import httpRequest from '@/utils/network/request';
import {consoleApiUrl} from '@/config/url.js';
// 创建推送消息
export const pushAppMsg = function (params) {
  const url = `${consoleApiUrl}/console/push/create`;
  return httpRequest.post(url, params);
};

// 编辑推送消息
export const editPushMsg = function (params) {
  const url = `${consoleApiUrl}/console/push/update`;
  return httpRequest.post(url, params);
};

// 开始/暂停 推送消息
export const startPushMsg = function (params) {
  const url = `${consoleApiUrl}/console/push/start`;
  return httpRequest.post(url, params);
};

// 停止推送消息
export const stopPushMsg = function (params) {
  const url = `${consoleApiUrl}/console/push/stop`;
  return httpRequest.post(url, params);
};

// 获取推送消息列表
export const getPushMsgHistory = function (params) {
  const url = `${consoleApiUrl}/console/push/querylist`;
  return httpRequest.post(url, params);
};