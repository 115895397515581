<template>
  <div class="pushv2-container">
    <div class="head">
      <el-tooltip placement="bottom">
        <pre slot="content">{{helpContent}}</pre>
        <el-button type="primary">规则说明</el-button>
      </el-tooltip>
      <el-button type="primary" @click="addPush">新建</el-button>
    </div>
    <el-table
      :data="list"
      stripe
      style="width: 100%"
      v-loading="loading"
      height="calc(100% - 30px )"
      @row-dblclick="editPush"
    >
      <el-table-column align="center" type="index" label="序号" width="50">
      </el-table-column>
      <el-table-column align="center" prop="pushId" label="推送ID" width="80">
      </el-table-column>
      <el-table-column
        align="center"
        width="100"
        prop="pushState"
        label="推送状态"
        :formatter="formatPushState"
      >
      </el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template v-if="scope.row.pushState != 4" slot-scope="scope">
          <el-button type="text" @click="startPush(scope.row)">{{scope.row.pushState == 2 ? '暂停':'手动开始'}}</el-button>
          <el-button type="text" @click="endPush(scope.row)">结束</el-button>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="startPushTime"
        label="推送时间"
        width="100"
        :formatter="formatTime"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="beginActiveTime"
        label="开始活跃"
        width="100"
        :formatter="formatTime"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="endActiveTime"
        label="结束活跃"
        width="100"
        :formatter="formatTime"
      >
      </el-table-column>
      <!-- <el-table-column
        align="center"
        width="100"
        prop="pushTitle"
        :show-overflow-tooltip="true"
        label="推送标题"
      >
      </el-table-column>
      <el-table-column
        align="center"
        width="100"
        prop="pushContent"
        :show-overflow-tooltip="true"
        label="推送内容"
      >
      </el-table-column> -->
      <el-table-column prop="pushPhoto" label="图片" width="100" align="center">
          <!-- 图片的显示 -->
          <template   slot-scope="scope">
              <img :src="scope.row.pushPhoto"  min-width="50" height="50" />
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="expectPushNum"
        label="预计推送人数"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="realPushNum"
        label="实际推送人数"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="arrivePushNum"
        label="触达人数"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="openPushNum"
        label="打开人数"
        width="100"
      >
      </el-table-column>
      <el-table-column align="center" label="总打开率" width="120">
        <template slot-scope="scope">{{
          scope.row.openPushNum && scope.row.realPushNum
            ? ((scope.row.openPushNum / scope.row.realPushNum) * 100).toFixed(2) +
              "%"
            : ""
        }}</template>
      </el-table-column>
      <el-table-column align="center" label="触达打开率" width="120">
        <template slot-scope="scope">{{
          scope.row.openPushNum && scope.row.arrivePushNum
            ? ((scope.row.openPushNum / scope.row.arrivePushNum) * 100).toFixed(2) +
              "%"
            : ""
        }}</template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="createTime"
        label="创建时间"
        width="100"
        :formatter="formatTime"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="editTime"
        label="最后一次更新时间"
        width="100"
        :formatter="formatTime"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="userOpts"
        label="操作人记录"
        :show-overflow-tooltip="true"
        width="100"
      >
      </el-table-column>
    </el-table>
    <el-dialog
      class="pushv2-dialog"
      center
      :title="dialogType === 'edit' ? '编辑' : '新建'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="600px"
      @close="beforeDialogClose"
    >
      <el-form
        :model="editform"
        ref="editform"
        label-width="80px"
        size="small"
        :rules="rules"
        :disabled="editform.disabled"
      >
        <el-form-item label-width="180px" label="选择图片上传：">
            <input type="file"
                    accept="image/*"
                    @change="photoUpload">
        </el-form-item>
        <el-form-item label="推送ID" prop="pushId">
          <el-input
            placeholder="自动生成不可更改"
            v-model="editform.pushId"
            readonly
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="图片：">
            <el-input v-model="editform.pushPhoto" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="推送时间" prop="pushTime">
          <el-date-picker
            :popper-class="'pushPicker'"
            v-model="editform.pushTime"
            type="datetime"
            placeholder="推送开始时间"
            :disabled="editform.rangeDisabled"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="活跃人群" prop="pushRange">
          <el-date-picker
            v-model="editform.pushRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :disabled="editform.rangeDisabled"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="白名单" prop="whiteList">
          <el-input
            placeholder="输入userid，用英文逗号分隔"
            v-model="editform.whiteList"
            type="textarea"
            :autosize="{ minRows: 2 }"
          ></el-input>
        </el-form-item>
        <el-form-item label="推送内容">
          <el-radio v-model="editform.userRange" label="1">全部用户</el-radio>
          <el-radio v-model="editform.userRange" label="2">按空间尾号区分</el-radio>
          <el-checkbox v-model="editform.hasAnni">有无纪念日</el-checkbox>
        </el-form-item>
        <el-card >
          <div slot="header">
            <span>标题</span>
          </div>
          <el-form-item v-for="(item, index) in titleList" :key="index" label-width='120px' :label="item.title">
            <el-input
              @input="forceUpdate"
              placeholder="输入标题（20字以内）"
              maxlength=20
              v-model="item.content"
            ></el-input>
          </el-form-item>
        </el-card>
        <el-card style="margin-top:20px;">
          <div slot="header">
            <span>副标题</span>
          </div>
          <el-form-item v-for="(item, index) in contentList" :key="index" label-width='120px' :label="item.title">
            <el-input
              @input="forceUpdate"
              placeholder="输入副标题（50字以内）"
              maxlength=50
              v-model="item.content"
            ></el-input>
          </el-form-item>
        </el-card>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="!editform.disabled" type="primary" @click="save" :loading="onSubmit"
          >保 存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getPushMsgHistory, pushAppMsg, editPushMsg, startPushMsg, stopPushMsg } from '@/api/pushMsgV2.js';
import moment from 'moment';
import axios from 'axios';
import {consoleApiUrl} from '@/config/url.js';
import app from '@/config/app.js';

export default {
  name: 'pushv2',
  props: {},
  data () {
    const rules = {
      pushTime: [
        {
          validator: (rule, value, callback) => {
            let timestamp = new Date().getTime();
            if (value < timestamp + 10 * 60 * 1000) {
              callback(new Error('请选择十分钟以后的时间'));
            } else {
              callback();
            }
          },
          trigger: 'change'
        }
      ],
      pushRange: [
        {
          validator: (rule, value, callback) => {
            if (!value && !this.editform.whiteList) {
              callback(new Error('活跃人群和白名单不能都为空'));
            } else {
              callback();
            }
          },
          trigger: 'change'
        }
      ]

    };
    return {
      helpContent: '',
      list: [],
      loading: false,
      dialogVisible: false,
      onSubmit: false,
      dialogType: 'add',
      rules,
      editform: {
        id: null,
        pushTime: null,
        pushRange: null,
        whiteList: null,
        userRange: '1',
        hasAnni: false,
        pushContent: null,
        disabled: false,    // 控制所有选项
        rangeDisabled: false,   // 控制活跃日期
        pushPhoto: null,
        pushTitle: null
      }
    };
  },

  computed: {
    contentList: function () {
      let arr = [{
        title: '',
        content: ''
      }];
      let pushContent = this.editform.pushContent ? JSON.parse(this.editform.pushContent).value : '';
      if (this.editform.userRange == '1') {
        if (this.editform.hasAnni) {
          arr = [{
            title: '全部、有纪念日',
            content: pushContent ? pushContent[0] : ''
          }, {
            title: '全部、无纪念日',
            content: pushContent ? pushContent[1] : ''
          }];
        } else {
          arr = [{
            title: '',
            content: pushContent ? pushContent[0] : ''
          }];
        }
      } else {
        if (this.editform.hasAnni) {
          arr = [{
            title: '单号、有纪念日',
            content: pushContent ? pushContent[0] : ''
          }, {
            title: '单号、无纪念日',
            content: pushContent ? pushContent[1] : ''
          }, {
            title: '双号、有纪念日',
            content: pushContent ? pushContent[2] : ''
          }, {
            title: '双号、无纪念日',
            content: pushContent ? pushContent[3] : ''
          }];
        } else {
          arr = [{
            title: '单号',
            content: pushContent ? pushContent[0] : ''
          }, {
            title: '双号',
            content: pushContent ? pushContent[1] : ''
          }];
        }
      }
      return arr;
    },
    titleList: function () {
      let arr = [{
        title: '',
        content: ''
      }];
      let pushTitle = this.editform.pushTitle ? JSON.parse(this.editform.pushTitle).value : '';
      if (this.editform.userRange == '1') {
        if (this.editform.hasAnni) {
          arr = [{
            title: '全部、有纪念日',
            content: pushTitle ? pushTitle[0] : ''
          }, {
            title: '全部、无纪念日',
            content: pushTitle ? pushTitle[1] : ''
          }];
        } else {
          arr = [{
            title: '',
            content: pushTitle ? pushTitle[0] : ''
          }];
        }
      } else {
        if (this.editform.hasAnni) {
          arr = [{
            title: '单号、有纪念日',
            content: pushTitle ? pushTitle[0] : ''
          }, {
            title: '单号、无纪念日',
            content: pushTitle ? pushTitle[1] : ''
          }, {
            title: '双号、有纪念日',
            content: pushTitle ? pushTitle[2] : ''
          }, {
            title: '双号、无纪念日',
            content: pushTitle ? pushTitle[3] : ''
          }];
        } else {
          arr = [{
            title: '单号',
            content: pushTitle ? pushTitle[0] : ''
          }, {
            title: '双号',
            content: pushTitle ? pushTitle[1] : ''
          }];
        }
      }
      return arr;
    }
  },

  created () {
    let arr = [
      '推送消息后台规则',
      '',
      '推送顺序：优先白名单->然后从最新活跃开始',
      '',
      '【新建推送】',
      '推送时间——开始推送的时间，只能选择10分钟以后的时间',
      '活跃人群——App活跃的结对用户，最小可选择前一天的活跃',
      '',
      '【编辑推送】',
      '在暂停状态及待推送状态时，可以二次编辑',
      '暂停状态下编辑——活跃人群不可编辑，推送文案和白名单可编辑',
      '编辑成功后若再次开启推送，已推送过的不影响',
      '',
      '【推送操作】',
      '手动开始——推送时间未到前，可点击直接开始',
      '暂停——暂停当前推送进程，已推送过的不影响',
      '继续——点击继续推送，白名单用户会再次重新推送，活跃人群会从未推送的用户继续推',
      '结束——点击结束后，无法再重新开启推送'
    ];
    this.helpContent = arr.join('\n');
    this.getList();
  },
  methods: {
    forceUpdate () {
      this.$forceUpdate();  // 强制刷新
    },
    formatTime (row, col, timestamp) {
      if (!timestamp) {return '';}
      return moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
    },
    formatPushState (row, col, state) {
      let map = {
        1: '待推送',
        2: '推送中',
        3: '暂停推送',
        4: '推送结束'
      };
      return map[state];
    },
    addPush () {
      this.dialogType = 'add';
      this.dialogVisible = true;
    },
    async startPush (row) {
      const {pushId, pushState} = row;
      try {
        const res = await startPushMsg({pushId, startType: pushState == 2 ? 1 : 0});
        if (res.result == 1) {
          this.$message({
            type: 'success',
            message: '修改状态成功'
          });
          this.getList();
        } else {
          this.$message({
            type: 'error',
            message: '修改状态失败'
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async endPush (row) {
      const {pushId} = row;
      try {
        const res = await stopPushMsg({pushId});
        if (res.result == 1) {
          this.$message({
            type: 'success',
            message: '推送已停止'
          });
          this.getList();
        } else {
          this.$message({
            type: 'error',
            message: '停止失败'
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    editPush (row) {
      let pushContent = JSON.parse(row.pushContent);
      let userRange = '1';
      let hasAnni = false;
      switch (pushContent.key) {
      case '1':
      {
        userRange = '1';
        hasAnni = false;
        break;
      }
      case '2':
      {
        userRange = '1';
        hasAnni = true;
        break;
      }
      case '3':
      {
        userRange = '2';
        hasAnni = false;
        break;
      }
      case '4':
      {
        userRange = '2';
        hasAnni = true;
        break;
      }
      default:
        break;
      }
      this.editform = {
        pushId: row.pushId,
        pushPhoto: row.pushPhoto,
        pushTime: new Date(row.startPushTime),
        pushRange: row.beginActiveTime ? [new Date(row.beginActiveTime), new Date(row.endActiveTime)] : null,
        whiteList: row.whiteList ? JSON.parse(row.whiteList).join(',') : '',
        userRange,
        hasAnni,
        pushTitle: row.pushTitle,
        pushContent: row.pushContent,
        disabled: row.pushState == 4,
        rangeDisabled: row.pushState != 1
      };
      this.dialogType = 'edit';
      this.dialogVisible = true;
    },
    save () {
      let that = this;
      if (that.onSubmit) {
        return;
      }
      that.onSubmit = true;
      that.$refs.editform.validate(async (valid) => {
        if (valid) {
          let key = '';
          let value = [];
          let titleKey = '';
          let titleValue = [];
          that.contentList.forEach((item) => {
            value.push(item.content);
          });
          that.titleList.forEach((item) => {
            titleValue.push(item.content);
          });
          if (this.editform.userRange == '1') {
            if (this.editform.hasAnni) {
              key = '2';
              titleKey = '2';
            } else {
              key = '1';
              titleKey = '1';
            }
          } else {
            if (this.editform.hasAnni) {
              key = '4';
              titleKey = '4';
            } else {
              key = '3';
              titleKey = '3';
            }
          }

          try {
            let data = {
              pushId: this.editform.pushId,
              pushPhoto: this.editform.pushPhoto,
              startPushTime: this.editform.pushTime.getTime(),
              beginActiveTime: this.editform.pushRange ? this.editform.pushRange[0].getTime() : 0,
              endActiveTime: this.editform.pushRange ? this.editform.pushRange[1].getTime() : 0,
              whiteList: this.editform.whiteList ? JSON.stringify(this.editform.whiteList.split(',')) : '',
              pushTitle: JSON.stringify({key: titleKey, value: titleValue}),
              pushContent: JSON.stringify({key, value})
            };
            const res = this.dialogType == 'add' ? await pushAppMsg(data) : await editPushMsg(data);
            if (res.result !== 1) {
              throw new Error(res.error_msg);
            }
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.dialogVisible = false;
            this.getList();
          } catch (error) {
            this.$message({
              type: 'error',
              message: error.message
            });
          }
        }
        this.onSubmit = false;
      });
    },
    beforeDialogClose () {
      this.$refs.editform.resetFields();
      this.editform = {
        pushId: null,
        pushTime: null,
        pushRange: null,
        whiteList: null,
        userRange: '1',
        hasAnni: false,
        pushTitle: null,
        pushContent: null,
        disabled: false,
        rangeDisabled: false
      };
      this.dialogType = 'add';
    },
    async getList () {
      this.loading = true;
      try {
        const res = await getPushMsgHistory({

        });
        console.log('推送历史记录获取成功：', res);
        if (res.error_msg) {
          this.$message({
            type: 'error',
            message: res.error_msg
          });
        } else {
          let list = res;
          this.list = list;
        }
      } catch (error) {
        this.$message({
          type: 'error',
          message: '推送记录获取失败'
        });
        console.log('推送历史记录获取失败：', error);
      }
      this.loading = false;
    },
    photoUpload (e) {
      console.log('1111111', e);
      let that = this;
      let file = e.target.files[0];
      let param = new FormData();  // 创建form对象
      param.append('photo', file);  // 通过append向form对象添加数据
      // param.append('chunk', '0') // 添加form表单中其他数据
      console.log(param.get('file')); // FormData私有类对象，访问不到，可以通过get判断值是否传进去
      let config = {
        headers: {'Content-Type': 'multipart/form-data'}
      };
      let requestUrl = `${consoleApiUrl}/console/push/photo_upload?access_token=${app.globalData.accessToken}`;
      // 添加请求头
      axios.post(requestUrl, param, config)
        .then((res) => {
          console.log('上传图片成功:', res.data);
          if (res.data.result == 1) {
            that.$message({
              message: '图片上传成功了',
              type: 'success'
            });
            that.editform.pushPhoto = res.data.original_url;
          }
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" >
.pushv2-container {
  // background: #fff;
  width: calc(100% - 24px);
  height: calc(100vh - 24px);
  padding: 22px;
  box-sizing: border-box;
  margin: 12px;
  background: #fff;
  position: relative;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding-bottom: 12px;
  }
  .el-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
}
.pushv2-dialog {
  .el-range-editor {
    width: 100%;
  }
}
.pushPicker >>> .el-picker-panel__link-btn {
    display: none
}
.pushPicker > .el-picker-panel__footer > .el-button--text:first-child{
  display: none;
}
</style>
